/**
 * Creates and initilizes the module widgets
 */

(function (angular) {
    'use strict';


    angular.module('userPicker', ['ui.bootstrap']);

})(angular, undefined);


